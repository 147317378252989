var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit, invalid }) {
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.createReport)
                      },
                    },
                  },
                  [
                    _c(
                      "b-card",
                      [
                        _c(
                          "b-card-text",
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "b-button-toolbar",
                                          [
                                            _c(
                                              "b-button-group",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    label: "Clienti",
                                                    name: "Anagrafica",
                                                    rules: { required: true },
                                                    readonly: true,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.inpt_label_registry,
                                                    callback: function ($$v) {
                                                      _vm.inpt_label_registry =
                                                        $$v
                                                    },
                                                    expression:
                                                      "inpt_label_registry",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mt-4",
                                                    attrs: {
                                                      size: "sm",
                                                      text: "Button",
                                                      variant: "lisaweb",
                                                      title: "Ricerca Veloce",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.openQuickSearchRegistry,
                                                    },
                                                  },
                                                  [
                                                    _c("b-icon", {
                                                      attrs: { icon: "search" },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mt-4 ml-1",
                                                    attrs: {
                                                      size: "sm",
                                                      text: "Button",
                                                      variant: "lisaweb",
                                                      title:
                                                        "Elimina Anagrafica",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.resetRegistryId,
                                                    },
                                                  },
                                                  [
                                                    _c("b-icon", {
                                                      attrs: { icon: "trash" },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("quick-search-registry-modal", {
                                  ref: "quickSearchRegistry",
                                  on: { input: _vm.handleRegistryModalInput },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _vm.registry_data
                                      ? _c("span", {
                                          staticClass: "info",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.toInfoData(
                                                _vm.registry_data,
                                                "registry"
                                              )
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.form.request_input.clienti
                              ? _c("base-select", {
                                  attrs: {
                                    name: "report_type",
                                    vid: "report_type",
                                    label: "Tipo Report",
                                    options: [
                                      { text: "Gruppi", value: "gruppi" },
                                      { text: "Relazioni", value: "relazioni" },
                                    ],
                                  },
                                  on: { input: _vm.onInputReportType },
                                  model: {
                                    value: _vm.report_type,
                                    callback: function ($$v) {
                                      _vm.report_type = $$v
                                    },
                                    expression: "report_type",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.report_type === "gruppi"
                              ? _c(
                                  "b-card",
                                  {
                                    staticClass: "mt-2 mb-2",
                                    attrs: { header: "Gruppi" },
                                  },
                                  [
                                    _c(
                                      "b-card-text",
                                      [
                                        _c("base-select", {
                                          attrs: {
                                            vid: "gruppi",
                                            name: "gruppi",
                                            label: "Gruppo",
                                            options: _vm.registryGroupOptions,
                                            rules: { required: true },
                                          },
                                          on: {
                                            input: _vm.onInputRegistryGroup,
                                          },
                                          model: {
                                            value:
                                              _vm.form.request_input.gruppi,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.request_input,
                                                "gruppi",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.request_input.gruppi",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.report_type === "relazioni"
                              ? _c(
                                  "b-card",
                                  {
                                    staticClass: "mt-2 mb-2",
                                    attrs: { header: "Relazioni" },
                                  },
                                  [
                                    _c(
                                      "b-card-text",
                                      [
                                        _c("base-select", {
                                          attrs: {
                                            vid: "relazioni",
                                            name: "relazioni",
                                            label: "Relazioni",
                                            multiple: true,
                                            taggable: true,
                                            options: _vm.relationOptions,
                                            rules: { required: true },
                                          },
                                          model: {
                                            value:
                                              _vm.form.request_input.relazioni,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.request_input,
                                                "relazioni",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.request_input.relazioni",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "formato_aggrecli",
                                name: "formato_aggrecli",
                                label: "Versione Scheda",
                                options: _vm.convertArrayToObject(
                                  _vm.beFormReport.request_input
                                    .formato_aggrecli.options
                                ),
                                rules: { required: true },
                              },
                              model: {
                                value: _vm.form.request_input.formato_aggrecli,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "formato_aggrecli",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.request_input.formato_aggrecli",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                name: _vm.beFormReport.format.label,
                                vid: "format",
                                label: _vm.beFormReport.format.label,
                                rules: { required: true },
                                options: _vm.allowedFormatOptions,
                              },
                              model: {
                                value: _vm.form.format,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "format", $$v)
                                },
                                expression: "form.format",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: {
                              type: "submit",
                              disabled: invalid,
                              variant: "outline-lisaweb",
                              size: "sm",
                            },
                          },
                          [_vm._v("\n          Salva\n        ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }