var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit, invalid }) {
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.createReportCustom)
                      },
                    },
                  },
                  [
                    _c(
                      "b-card",
                      [
                        _c(
                          "b-card-text",
                          [
                            _c("base-select", {
                              attrs: {
                                name: _vm.beFormReport.format.label,
                                vid: "format",
                                label: _vm.beFormReport.format.label,
                                options: _vm.allowedFormatOptions,
                                rules: { required: true },
                              },
                              model: {
                                value: _vm.form.format,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "format", $$v)
                                },
                                expression: "form.format",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-month-picker", {
                              attrs: {
                                vid: "mese_da",
                                name: "mese_da",
                                label: "Incassi dal mese",
                                clearable: true,
                                rules: { required: true },
                                monthOnly: _vm.isMonthOnly,
                              },
                              model: {
                                value: _vm.form.request_input.mese_da,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "mese_da",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.mese_da",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-month-picker", {
                              attrs: {
                                vid: "mese_a",
                                name: "mese_a",
                                label: "Incassi al mese",
                                clearable: true,
                                rules: { required: true },
                                monthOnly: _vm.isMonthOnly,
                              },
                              model: {
                                value: _vm.form.request_input.mese_a,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "mese_a",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.mese_a",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-input", {
                              attrs: {
                                vid: "anno",
                                name: "anno",
                                label: "Incassi anno",
                                placeholder:
                                  _vm.beFormReport.request_input.anno.label,
                                type: "number",
                                maxlength: "4",
                                rules: {
                                  required: true,
                                  between: { min: "1900", max: "2050" },
                                },
                              },
                              model: {
                                value: _vm.form.request_input.anno,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.request_input, "anno", $$v)
                                },
                                expression: "form.request_input.anno",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "compagnie",
                                name: "compagnie",
                                label: "Compagnia",
                                taggable: true,
                                disabled: _vm.insurersDisabled,
                                options: _vm.getInsurers(),
                              },
                              model: {
                                value: _vm.form.request_input.compagnie,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "compagnie",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.compagnie",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "tipi_rischio",
                                name: "tipi_rischio",
                                label: "Tipi di Rischio",
                                options: _vm
                                  .getRisks()
                                  .filter((risk) =>
                                    ["P", "L", "D"].includes(risk.code)
                                  ),
                              },
                              on: { input: _vm.onInputRiskTypes },
                              model: {
                                value: _vm.form.request_input.tipi_rischio,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "tipi_rischio",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.tipi_rischio",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                name: "raggruppa_produttori",
                                vid: "raggruppa_produttori",
                                label: "Raggruppare i produttori",
                                options: [
                                  { value: "Y", text: "Si" },
                                  { value: "N", text: "No" },
                                ],
                                rules: { required: true },
                              },
                              model: {
                                value:
                                  _vm.form.request_input.raggruppa_produttori,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "raggruppa_produttori",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.request_input.raggruppa_produttori",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                name: "stampa_polizze",
                                vid: "stampa_polizze",
                                label: "Stampare anche il dettaglio polizze",
                                options: [
                                  { value: "Y", text: "Si" },
                                  { value: "N", text: "No" },
                                ],
                                rules: { required: true },
                              },
                              model: {
                                value: _vm.form.request_input.stampa_polizze,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "stampa_polizze",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.stampa_polizze",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                name: "tutte_polizze",
                                vid: "tutte_polizze",
                                label:
                                  "Considerare anche polizze stornate/annullate/disdettate nel periodo considerato",
                                options: [
                                  { value: "Y", text: "Si" },
                                  { value: "N", text: "No" },
                                ],
                                rules: { required: true },
                              },
                              model: {
                                value: _vm.form.request_input.tutte_polizze,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "tutte_polizze",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.tutte_polizze",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "produttori",
                                name: "produttori",
                                label: "Produttori",
                                taggable: true,
                                multiple: true,
                                closeOnSelect: false,
                                rules: { required: true },
                                options: _vm.getSalesmen(),
                              },
                              model: {
                                value: _vm.form.request_input.produttori,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "produttori",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.produttori",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: {
                              type: "submit",
                              disabled: invalid,
                              variant: "outline-lisaweb",
                              size: "sm",
                            },
                          },
                          [_vm._v("\n          Salva\n        ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }