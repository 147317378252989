var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit, invalid }) {
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.createReport)
                      },
                    },
                  },
                  [
                    _c(
                      "b-card",
                      [
                        _c(
                          "b-card-text",
                          [
                            _c("base-select", {
                              attrs: {
                                vid: "relazioni",
                                name: "relazioni",
                                label: "Relazioni",
                                options: _vm.beFormReport.relazioni.options,
                                rules: { required: true },
                              },
                              on: { input: _vm.onInputRelations },
                              model: {
                                value: _vm.form.request_input.relazioni,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "relazioni",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.relazioni",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "compagnie",
                                name: "compagnie",
                                label: "Compagnie",
                                options: _vm.getInsurers(),
                                taggable: true,
                                multiple: true,
                              },
                              on: { input: _vm.onInputInsurer },
                              model: {
                                value: _vm.form.request_input.compagnie,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "compagnie",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.compagnie",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "rami",
                                name: "rami",
                                label: "Rami",
                                options: _vm.getBranches()(),
                                taggable: true,
                                multiple: true,
                              },
                              model: {
                                value: _vm.form.request_input.rami,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.request_input, "rami", $$v)
                                },
                                expression: "form.request_input.rami",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "prodotti",
                                name: "prodotti",
                                label: "Prodotti",
                                options: _vm.getRisks(),
                                taggable: true,
                                multiple: true,
                                disabled: _vm.risksDisabled,
                              },
                              model: {
                                value: _vm.form.request_input.prodotti,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "prodotti",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.prodotti",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "formato_polclirel",
                                name: "formato_polclirel",
                                label: "Formato",
                                options: _vm.convertArrayToObject(
                                  _vm.beFormReport.request_input
                                    .formato_polclirel.options
                                ),
                                rules: { required: true },
                              },
                              model: {
                                value: _vm.form.request_input.formato_polclirel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "formato_polclirel",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.request_input.formato_polclirel",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                name: _vm.beFormReport.format.label,
                                vid: "format",
                                label: _vm.beFormReport.format.label,
                                rules: { required: true },
                                options: _vm.allowedFormatOptions,
                              },
                              model: {
                                value: _vm.form.format,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "format", $$v)
                                },
                                expression: "form.format",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: {
                              type: "submit",
                              disabled: invalid,
                              variant: "outline-lisaweb",
                              size: "sm",
                            },
                          },
                          [_vm._v("\n          Salva\n        ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }