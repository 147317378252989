var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit, invalid }) {
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.createReport)
                      },
                    },
                  },
                  [
                    _c(
                      "b-card",
                      [
                        _c(
                          "b-card-text",
                          [
                            _c("base-radio", {
                              attrs: {
                                name: _vm.beFormReport.format.label,
                                vid: "format",
                                label: _vm.beFormReport.format.label,
                                options: _vm.allowedFormatOptions,
                                rules: { required: true },
                              },
                              model: {
                                value: _vm.form.format,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "format", $$v)
                                },
                                expression: "form.format",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-datepicker", {
                              attrs: {
                                vid: "data_da",
                                name: "Data da",
                                label: "Data da",
                                rules: { required: true },
                              },
                              model: {
                                value: _vm.form.request_input.data_da,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "data_da",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.data_da",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-datepicker", {
                              attrs: {
                                vid: "data_a",
                                name: "Data a",
                                label: "Data a",
                                rules: { required: true },
                              },
                              model: {
                                value: _vm.form.request_input.data_a,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "data_a",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.data_a",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "compagnie",
                                name: "compagnie",
                                label: "Compagnie",
                                options: _vm.getInsurers(),
                                taggable: true,
                                multiple: true,
                              },
                              model: {
                                value: _vm.form.request_input.compagnie,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "compagnie",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.compagnie",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "rami",
                                name: "rami",
                                label: "Rami",
                                taggable: true,
                                multiple: true,
                                options: _vm.getBranches()(),
                              },
                              model: {
                                value: _vm.form.request_input.rami,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.request_input, "rami", $$v)
                                },
                                expression: "form.request_input.rami",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "produttori",
                                name: "produttori",
                                label: "Produttori",
                                taggable: true,
                                multiple: true,
                                options: _vm.getSalesmen(),
                              },
                              model: {
                                value: _vm.form.produttori,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "produttori", $$v)
                                },
                                expression: "form.produttori",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "formato_incacomp",
                                name: "formato_incacomp",
                                label: "Livello Dettaglio",
                                options: _vm.convertArrayToObject(
                                  _vm.beFormReport.request_input
                                    .formato_incacomp.options
                                ),
                              },
                              model: {
                                value: _vm.form.formato_incacomp,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "formato_incacomp", $$v)
                                },
                                expression: "form.formato_incacomp",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "formato_premi_provv",
                                name: "formato_premi_provv",
                                label: "Visualizza Importi",
                                options: _vm.convertArrayToObject(
                                  _vm.beFormReport.request_input
                                    .formato_premi_provv.options
                                ),
                              },
                              model: {
                                value: _vm.form.formato_premi_provv,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "formato_premi_provv", $$v)
                                },
                                expression: "form.formato_premi_provv",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: {
                              type: "submit",
                              disabled: invalid,
                              variant: "outline-lisaweb",
                              size: "sm",
                            },
                          },
                          [_vm._v("\n          Salva\n        ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }