var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.code,
        size: "lg",
        "hide-footer": "",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "clipboard" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Crea Report")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      cancel(), _vm.close()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.code === "POLCLIREL"
        ? _c("polclirel", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "MONUPROD"
        ? _c("monuprod", {
            attrs: {
              reportCode: _vm.code,
              reportId: _vm.reportId,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "RUBRICLI"
        ? _c("rubricli", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "POLCLIGRU"
        ? _c("policligru", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "SCACLIGRU"
        ? _c("scaligru", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "PORTATTI"
        ? _c("portatti", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "RUBRIGRU"
        ? _c("rubrigru", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "LISPOL"
        ? _c("lispol", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "COMINCA"
        ? _c("cominca", {
            attrs: {
              reportCode: _vm.code,
              reportId: _vm.reportId,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "RENCOMP"
        ? _c("rencomp", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "RENPROD"
        ? _c("renprod", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "INCACOMP"
        ? _c("incacomp", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "INCACOL"
        ? _c("incacol", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "INCARAM"
        ? _c("incaram", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "REGIFOCA"
        ? _c("regifoca", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "TOTAFOCA"
        ? _c("totafoca", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "FOCACOL"
        ? _c("focacol", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "INCAPAGA"
        ? _c("incapaga", {
            attrs: {
              reportCode: _vm.code,
              reportId: _vm.reportId,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "INCARAMTI"
        ? _c("incaramti", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "INCACLIGRU"
        ? _c("incacligru", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "INCAPROD"
        ? _c("incaprod", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "TOTASOSP"
        ? _c("totasosp", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "LISTIT"
        ? _c("listit", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "LISTITINCA"
        ? _c("listitinca", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "TITULTIGI"
        ? _c("titultigi", {
            attrs: {
              reportCode: _vm.code,
              reportId: _vm.reportId,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "TITINCACLI"
        ? _c("titincacli", {
            attrs: {
              reportCode: _vm.code,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "AGGRECLI"
        ? _c("aggrecli", {
            attrs: {
              reportCode: _vm.code,
              reportId: _vm.reportId,
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
              allowedFormatOptions: _vm.allowedFormatOptions,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "INCACOMP2"
        ? _c("incacomp2", {
            attrs: { reportCode: "INCACOMP2", reportId: _vm.reportId },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "CONREGCOL"
        ? _c("conregcol", {
            attrs: {
              reportCode: "CONREGCOL",
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.code === "RENCOLLAB"
        ? _c("rencollab", {
            attrs: {
              reportCode: "RENCOLLAB",
              beFormReport: _vm.beFormCreate,
              beRulesReport: _vm.beRulesCreate,
            },
            on: { saveReport: _vm.save },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }