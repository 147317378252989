var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit, invalid }) {
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.createReport)
                      },
                    },
                  },
                  [
                    _c(
                      "b-card",
                      [
                        _c(
                          "b-card-text",
                          [
                            _c("base-select", {
                              attrs: {
                                name: _vm.beFormReport.format.label,
                                vid: "format",
                                label: _vm.beFormReport.format.label,
                                options: _vm.allowedFormatOptions,
                                rules: { required: true },
                              },
                              model: {
                                value: _vm.form.format,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "format", $$v)
                                },
                                expression: "form.format",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: {
                              type: "submit",
                              disabled: invalid,
                              variant: "outline-lisaweb",
                              size: "sm",
                            },
                          },
                          [_vm._v("\n          Salva\n        ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }