var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit, invalid }) {
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.createReport)
                      },
                    },
                  },
                  [
                    _c(
                      "b-card",
                      [
                        _c(
                          "b-card-text",
                          [
                            _c("base-radio", {
                              attrs: {
                                name: "format",
                                vid: "format",
                                label: "Formato",
                                options: _vm.allowedFormatOptions,
                                rules: { required: true },
                              },
                              model: {
                                value: _vm.form.format,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "format", $$v)
                                },
                                expression: "form.format",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                vid: "clienti",
                                name: "clienti",
                                label: "Cliente",
                                options: _vm.registryOptions,
                                isLoading: _vm.isLoadingAjax,
                                placeholder: "Digita un nominativo",
                                rules: { required: true },
                              },
                              on: {
                                "search-change": _vm.getAjaxOptions,
                                input: _vm.onInputRegistry,
                              },
                              model: {
                                value: _vm.cliente,
                                callback: function ($$v) {
                                  _vm.cliente = $$v
                                },
                                expression: "cliente",
                              },
                            }),
                            _vm._v(" "),
                            _vm.cliente
                              ? _c("base-radio", {
                                  attrs: {
                                    name: "includi_gruppo",
                                    vid: "includi_gruppo",
                                    label: "Includi Gruppo",
                                    options: [
                                      {
                                        value: "Y",
                                        text: "Attiva selezione per gruppo anagrafico",
                                      },
                                      {
                                        value: "N",
                                        text: "Attiva selezione in base alle relazioni anagrafiche",
                                      },
                                    ],
                                  },
                                  on: { select: _vm.onSelectReportType },
                                  model: {
                                    value:
                                      _vm.form.request_input.includi_gruppo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.request_input,
                                        "includi_gruppo",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.request_input.includi_gruppo",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.form.request_input.includi_gruppo === "Y"
                              ? _c(
                                  "b-card",
                                  {
                                    staticClass: "mt-2 mb-2",
                                    attrs: { header: "Gruppi" },
                                  },
                                  [
                                    _c(
                                      "b-card-text",
                                      [
                                        _c("base-select", {
                                          attrs: {
                                            vid: "gruppi",
                                            name: "gruppi",
                                            label: "Gruppi",
                                            options: _vm.registryGroupOptions,
                                          },
                                          on: {
                                            input: _vm.onInputRegistryGroups,
                                          },
                                          model: {
                                            value:
                                              _vm.form.request_input.gruppi,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.request_input,
                                                "gruppi",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.request_input.gruppi",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("base-select", {
                                          attrs: {
                                            vid: "clienti",
                                            name: "clienti",
                                            label:
                                              "Cliente Associati al Gruppo",
                                            multiple: true,
                                            taggable: true,
                                            options: _vm.registriesOptions,
                                            placeholder: "Scegli un cliente",
                                          },
                                          model: {
                                            value:
                                              _vm.form.request_input.clienti,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.request_input,
                                                "clienti",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.request_input.clienti",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.form.request_input.includi_gruppo === "N"
                              ? _c(
                                  "b-card",
                                  {
                                    staticClass: "mt-2 mb-2",
                                    attrs: { header: "Relazioni" },
                                  },
                                  [
                                    _c(
                                      "b-card-text",
                                      [
                                        _c("base-select", {
                                          attrs: {
                                            vid: "relazioni",
                                            name: "relazioni",
                                            label: "Relazioni",
                                            multiple: true,
                                            taggable: true,
                                            options: _vm.relationOptions,
                                          },
                                          model: {
                                            value:
                                              _vm.form.request_input.relazioni,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.request_input,
                                                "relazioni",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.request_input.relazioni",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("base-datepicker", {
                              attrs: {
                                vid: "data_da",
                                name: "data_da",
                                label: "Periodo da",
                              },
                              model: {
                                value: _vm.form.request_input.data_da,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "data_da",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.data_da",
                              },
                            }),
                            _vm._v(" "),
                            _c("base-datepicker", {
                              attrs: {
                                vid: "data_a",
                                name: "data_a",
                                label: "Periodo a",
                              },
                              model: {
                                value: _vm.form.request_input.data_a,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.request_input,
                                    "data_a",
                                    $$v
                                  )
                                },
                                expression: "form.request_input.data_a",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: {
                              type: "submit",
                              disabled: invalid,
                              variant: "outline-lisaweb",
                              size: "sm",
                            },
                          },
                          [_vm._v("\n          Salva\n        ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }